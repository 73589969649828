.app {
  background-color: var(--primary-color);
  font-family: var(--font-base);
}

.app__whitebg {
  background-color: var(--white-color);
}

.app__primarybg {
  background-color: var(--primary-color);
}

.app__container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

.copyright {
  width: 100%;
  padding: 2rem 0 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  p {
    font-size: 0.7rem;
    text-transform: uppercase;
    color: var(--black-color);
  }
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  text-transform: capitalize;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  clip-path: auto;
  -webkit-clip-path: auto;
  white-space: normal;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.app__social,
.app__social-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  align-self: center;
  padding: 1rem;

  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}



.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  align-self: center;
  right: 0.25rem;
  
  padding: 1rem;

  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--secondary-color);
    }


    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

.app__social-footer {
  display: none;
}

@media screen and (max-width: 500px) {
  .app__navigation,
  .app__social {
    display: none;
  }

  .app__social-footer {
    display: flex;
    flex-direction: row;

    div {
      margin: 0 0.25rem;

      a {
        padding-top: 5px;
      }
    }
  }

  .copyright {
    padding: 2rem;
  }
}

.reset-button_container {
  display: flex;
  justify-content: center;
}

.reset-form__button {
  padding: 1rem 2rem;
  border-radius: 10px;
  border: none;
  background-color: var(--secondary-color);

  font-weight: 500;
  color: var(--white-color);
  outline: none;
  margin: 2rem 0 0 0;
  font-family: var(--font-base);

  transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  cursor: pointer;

  &:hover {
    background-color: #2430af;
  }

  @media screen and (max-width: 768px) {
    margin: 1rem 0;
  }
}
