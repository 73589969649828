@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: hsl(213, 44%, 95%);
  --secondary-color: hsl(164, 45%, 35%);
  --black-color: hsl(0, 0%, 1%);
  --lightGray-color: hsl(0, 0%, 89%);
  --gray-color: hsl(217, 12%, 48%);
  --brown-color: hsl(288, 16%, 25%);
  --white-color: hsl(0, 0%, 100%);
  --darkGrey-color: ;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
